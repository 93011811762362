import React from 'react';
import PropTypes from 'prop-types';
import Button from '../materialUiComps/Button';
import Typography from '../materialUiComps/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const ColorButton = withStyles(theme => ({
  root: {
    fontSize: 30,
    textTransform: 'none',
    color: theme.palette.getContrastText(grey[800]),
    backgroundColor: grey[800],
    '&:hover': {
      color: 'primary',
      backgroundColor: grey[300],
    },
  },
}))(Button);
const backgroundImage =
  'https://images.unsplash.com/photo-1482192596544-9eb780fc7f66?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80'
const styles = theme => ({
  background: {
    backgroundImage: `url('../images/NatureLake.jpg')`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
});

function ProductHero(props) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
      <ColorButton 
        variant="contained" 
        color="primary" 
        className={classes.margin}
        href='/sponsors'
      >
        Sponsor Us
      </ColorButton>
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);