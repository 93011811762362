import React from 'react';
import { BrowserRouter, Route, Switch, Link, NavLink } from 'react-router-dom';
import NonProfitPage from '../components/community/NonProfitPage';
import CommunityCollege from '../components/community/CommunityCollegesPage';
import BoardOfDirectorsPage from '../components/community/BoardOfDirectorsPage';
import SocialImpactPage from '../components/community/SocialImpactPage';
import HighSchoolProg from '../components/community/HighSchoolProgPage';
import NotFoundPage from '../components/NotFoundPage';
import Community from '../components/community/Community';
import Home from '../components/home/Home';
import ContactsPage from '../components/events/ContactsPage';
import EventsPage from '../components/events/EventsPage';
import SchedulePage from '../components/events/SchedulePage';
import CulturalFoodsPage from '../components/events/CulturalFoodsPage';
import DogJogPage from '../components/events/DogJogPage';
import PolarDivePage from '../components/events/PolarDivePage';
import MusicConcertPage from '../components/events/MusicConcertPage';
import EventCenter from '../components/events/EventCenter';
import TradeShowPage from '../components/events/TradeShowPage';
import PressPage from '../components/press/PressPage';
import SubscribePage from '../components/press/SubscribePage';
import DonationPage from '../components/sponsors/DonationPage';
import SponsorsPage from '../components/sponsors/SponsorsPage';
import SponsorTiersPage from '../components/sponsors/SponsorTiersPage';
import AboutUs from '../components/AboutUs';
import BootMenu from '../components/BootMenu';
import Connect from '../components/press/Connect';
import UpcomingPage from '../components/events/UpcomingPage';
import Members from '../components/community/Members';
import Bootstrap from "../components/Bootstrap/Bootstrap";

const AppRouter = () => (
  <BrowserRouter >
      {/* <Header /> */}
      <BootMenu />
      <Switch>
        <Route path="/" component={Home} exact={true} />
        <Route path="/about_us" component={AboutUs} exact={true} />
        <Route path="/community" component={Community} exact={true} />
        <Route path="/community/non_profits" component={NonProfitPage} />
        <Route path="/community/community_colleges" component={CommunityCollege} />
        <Route path="/community/board_of_directors" component={Members} />
        <Route path="/community/social_impact" component={SocialImpactPage} />
        <Route path="/community/high_school_programs" component={HighSchoolProg} />
        <Route path="/events" component={EventsPage} exact={true}/>
        <Route path="/events/event_center" component={EventCenter} />
        <Route path="/events/cultural_foods" component={CulturalFoodsPage} />
        <Route path="/events/music_concert" component={MusicConcertPage} />
        <Route path="/events/dog_jog" component={DogJogPage} />
        <Route path="/events/trade_show" component={TradeShowPage} />
        <Route path="/events/polar_dive" component={PolarDivePage} />
        <Route path="/events/schedule" component={SchedulePage} />
        <Route path="/events/upcoming" component={UpcomingPage} />
        <Route path="/sponsors" component={SponsorsPage} exact={true}/>
        {/* <Route path="/sponsors/forms" component={SponsorFormPage} /> */}
        <Route path="/sponsors/donations" component={DonationPage} />
        {/* <Route path="/sponsors/future_events" component={FutureEventsPage} /> */}
        <Route path="/sponsors/tiers" component={SponsorTiersPage} />
        <Route path="/press" component={PressPage} exact={true}/>
        <Route path="/press/connect" component={Connect}/>
        <Route path="/press/stay_in_touch" component={SubscribePage} />
        {/* <Route path="/news" component={NewsPage} exact={true}/> */}
        {/* <Route path="/news/concerns" component={ConcernsPage} />
        <Route path="/news/fun" component={FunPage} />
        <Route path="/news/saftey" component={ConcernsPage} /> */}
        <Route component={NotFoundPage} />
      </Switch>
      
  </BrowserRouter>
);

export default AppRouter;

// import React from 'react';
// import { BrowserRouter, Route, Switch, Link, NavLink } from 'react-router-dom';
// import NPRepresentationPage from '../components/communityPages/NPRepresentationPage';
// import WhyNPPage from '../components/communityPages/WhyNPPage';
// import BoardOfDirectorsPage from '../components/communityPages/BoardOfDirectorsPage';
// import SocialImpactPage from '../components/communityPages/SocialImpactPage';
// import ResponsibilityPage from '../components/communityPages/ResponsibilityPage';
// import NotFoundPage from '../components/NotFoundPage';
// import Header from '../components/Header';
// import Community from '../componentsHamz/Community';
// import Home from '../components/Home';
// import ContactsPage from '../components/eventsPages/ContactsPage';
// import EventsPage from '../components/eventsPages/EventsPage';
// import SchedulePage from '../components/eventsPages/SchedulePage';
// import UpcomingPage from '../components/eventsPages/UpcomingPage';
// import CautionPage from '../components/newsPages/CautionPage';
// import FunPage from '../components/newsPages/FunPage';
// import ConcernsPage from '../components/newsPages/ConcernsPage';
// import NewsPage from '../components/newsPages/NewsPage';
// import ContactUsPage from '../components/pressPages/ContactUsPage';
// import PressPage from '../components/pressPages/PressPage';
// import SubscribePage from '../components/pressPages/SubscribePage';
// import DonationPage from '../components/sponsorPages/DonationPage';
// import FutureEventsPage from '../components/sponsorPages/FutureEventsPage';
// import SponsorsPage from '../components/sponsorPages/SponsorsPage';
// import SponsorTiersPage from '../components/sponsorPages/SponsorTiersPage';
// import SponsorFormPage from '../components/sponsorPages/SponsorFormPage';
// import Connect from '../components/Connect';
// import AboutUs from '../components/AboutUs';
// import BootMenu from '../components/master/BootMenu';
// import Members from '../components/communityPages/Members';


// const AppRouter = () => (
//   <BrowserRouter >
//       <BootMenu />
//       <Switch>
//         <Route path="/" component={Home} exact={true} />
//         <Route path="/about_us" component={AboutUs} exact={true} />
//         <Route path="/community" component={Community} exact={true} />
//         <Route path="/community/non_profit_representation" component={NPRepresentationPage} />
//         <Route path="/community/why_non_profit" component={WhyNPPage} />
//         <Route path="/community/board_of_directors" component={Members} />
//         <Route path="/community/social_impact" component={SocialImpactPage} />
//         <Route path="/community/responsibility" component={ResponsibilityPage} />
//         <Route path="/events" component={EventsPage} exact={true}/>
//         <Route path="/events/upcoming" component={UpcomingPage} />
//         <Route path="/events/schedule" component={SchedulePage} />
//         <Route path="/events/contact" component={ContactsPage} />
//         <Route path="/sponsors" component={SponsorsPage} exact={true}/>
//         <Route path="/sponsors/donations" component={DonationPage} />
//         <Route path="/sponsors/tiers" component={SponsorTiersPage} />
//         <Route path="/press" component={PressPage} exact={true}/>
//         <Route path="/press/connect" component={Connect}/>
//         <Route component={NotFoundPage} />
//       </Switch>
    
//   </BrowserRouter>
// );

// export default AppRouter;
