import React, { Component } from "react";

class Bootstrap extends Component {
    render(){
        return (
            <div>
                <head>
                    <meta charset="utf-8"></meta>
                    <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
                    <title>Complete Bootstrap 4 Website Layout</title>
                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"></link>
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"></script>
                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
                    <script src="https://use.fontawesome.com/releases/v5.0.8/js/all.js"></script>
                    <link href="style.css" rel="stylesheet"></link>
                </head>
                <body>
                    
                    {/* <!-- Navigation --> */}
                    <nav className="navbar navbar-expand-md navbar-light bg-light sticky-top">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#">
                                <img src="img/logo.png"></img>
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarResponsive">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarResponsize">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Home</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>

                    {/* <!--- Image Slider --> */}
                    <div id="slides" className="carousel slide" data-ride="carusel">
                        <ul className="carusel-indicators">
                            <li data-target="#slides" data-slide-top="0" className="active"></li>
                            <li data-target="#slides" data-slide-top="0"></li>
                            <li data-target="#slides" data-slide-top="0"></li>

                        </ul>
                        <div className="carusel-inner">
                            <div className="carousel-item active">
                                <img src="img/background.png"></img>
                                <div className="carousel-caption">
                                    <h1 className="display-2">Bootstrap</h1>
                                    <h3>Complete Website Layout</h3>
                                    <button type="button" className="btn btn-outline-light btn-lg">
                                        DEMO
                                    </button>
                                    <button type="button" className="btn btn-primary btn-lg">
                                        DEMO
                                    </button>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src="img/background2.png"></img>
                            </div>
                            <div className="carousel-item">
                                <img src="img/background3.png"></img>
                            </div>
                        </div>
                    </div>

                    {/* <!--- Jumbotron --> */}


                    {/* <!--- Welcome Section --> */}


                    {/* <!--- Three Column Section --> */}


                    {/* <!--- Two Column Section --> */}


                    {/* <!--- Fixed background --> */}


                    {/* <!--- Emoji Section --> */}

                    
                    {/* <!--- Meet the team --> */}


                    {/* <!--- Cards --> */}


                    {/* <!--- Two Column Section --> */}


                    {/* <!--- Connect --> */}


                    {/* <!--- Footer --> */}

                </body>
            </div>
        )
    }
}

export default Bootstrap;