import React from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '../materialUiComps/Typography';
import Footer from '../../Footer';


const PolarEvent = () => (
  <React.Fragment>
  <Typography variant="h4" marked="center" align="center" component="h2">
         Polar Dive
     </Typography>
  <br></br>
  <div class="ui link cards">
  <div class="card">
  <div class="image">
    <img src="../images/Apache-Junction-BBQ.jpg"></img>
  </div>
  <div class="content">
    <div class="header">Apache Junction BBQ Showdown</div>
    <div class="meta">
      <a>April 22-24</a>
    </div>
    <div class="description">
      Apache Greyhound Park, 220 S. Delaware Dr./ Apache Juction, AZ 85120
    </div>
  </div>
 
</div>
<div class="card">
    <div class="image">
      <img src="/images/avatar2/large/molly.png"></img>
    </div>
    <div class="content">
      <div class="header">AZ Trade Show</div>
      <div class="meta">
        <a>TBD</a>
      </div>
      <div class="description">
        TBD
      </div>
    </div>
  </div>
  <div class="card">
    <div class="image">
      <img src="/images/avatar2/large/elyse.png"></img>
    </div>
    <div class="content">
      <div class="header">Cultural Foods</div>
      <div class="meta">
        <a>TBD</a>
      </div>
      <div class="description">
        TBD
      </div>
    </div>
  
  </div>
  <div class="card">
  <div class="image">
    <img src="/images/avatar2/large/elyse.png"></img>
  </div>
  <div class="content">
    <div class="header">Cultural Foods</div>
    <div class="meta">
      <a>TBD</a>
    </div>
    <div class="description">
      TBD
    </div>
  </div>

</div>
<div class="card">
<div class="image">
  <img src="/images/avatar2/large/elyse.png"></img>
</div>
<div class="content">
  <div class="header">Music Con</div>
  <div class="meta">
    <a>TBD</a>
  </div>
  <div class="description">
    TBD
  </div>
</div>

</div>
<div class="card">
<div class="image">
  <img src="/images/avatar2/large/elyse.png"></img>
</div>
<div class="content">
  <div class="header">Cultural Foods</div>
  <div class="meta">
    <a>TBD</a>
  </div>
  <div class="description">
    TBD
  </div>
</div>

</div>
</div>
<Footer/>
</React.Fragment>





)

export default PolarEvent;