import React from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '../materialUiComps/Typography';
import Footer from '../../Footer';
import pdf from '../../files/BBQ-Sponsor.pdf';


const TradeEvent = () => (
  <React.Fragment>
  <br></br>
  <Typography variant="h4" marked="center" align="center" component="h2">
         Event Center
     </Typography>
  <br></br>
  {/* <div className="card text-center">
  <div className="card-header">
    Featured Event
  </div>
  <div className="image">
    <img src="../images/Apache-Junction-BBQ.jpg"></img>
  </div>
  <div className="card-body">
    <div className="header">April 22-24</div>
    <div className="description">
      Apache Greyhound Park, 220 S. Delaware Dr./ Apache Juction, AZ 85120
    </div>
    <a href={pdf} target="_blank" className="btn btn-primary btn-lg">Sponsor Form</a>
  </div>
  <div className="card-footer text-muted">
  </div>
</div> */}
  <div className="ui link cards">
  
    <div className="card">
      <div className="card-header">
        Featured Event
      </div>
      <div className="image">
        <img src="../images/Apache-Junction-BBQ.jpg"></img>
      </div>
      <div className="content">
        <div className="header">Apache Junction BBQ Showdown</div>
        <div className="meta">
          <a>April 22-24</a>
        </div>
        <div className="description">
          Apache Greyhound Park, 220 S. Delaware Dr./ Apache Juction, AZ 85120
        </div>
        <div className="description">
          <a href={pdf} target="_blank">BBQ Showdown Sponsor Form</a>
        </div>
      </div>
    </div>
    
    <div className="card">
    <div className="image">
      <img src="/images/avatar2/large/molly.png"></img>
    </div>
    <div className="content">
      <div className="header">AZ Trade Show</div>
      <div className="meta">
        <a>TBD</a>
      </div>
      <div className="description">
        TBD
      </div>
    </div>
  </div>
  <div className="card">
    <div className="image">
      <img src="/images/avatar2/large/elyse.png"></img>
    </div>
    <div className="content">
      <div className="header">Cultural Foods</div>
      <div className="meta">
        <a>TBD</a>
      </div>
      <div className="description">
        TBD
      </div>
    </div>
  
  </div>
  <div className="card">
  <div className="image">
    <img src="/images/avatar2/large/elyse.png"></img>
  </div>
  <div className="content">
    <div className="header">Cultural Foods</div>
    <div className="meta">
      <a>TBD</a>
    </div>
    <div className="description">
      TBD
    </div>
  </div>

</div>
<div className="card">
<div className="image">
  <img src="/images/avatar2/large/elyse.png"></img>
</div>
<div className="content">
  <div className="header">Music Con</div>
  <div className="meta">
    <a>TBD</a>
  </div>
  <div className="description">
    TBD
  </div>
</div>

</div>
<div className="card">
<div className="image">
  <img src="/images/avatar2/large/elyse.png"></img>
</div>
<div className="content">
  <div className="header">Cultural Foods</div>
  <div className="meta">
    <a>TBD</a>
  </div>
  <div className="description">
    TBD
  </div>
</div>

</div>
</div>
<Footer/>
</React.Fragment>





)

export default TradeEvent;