import React from 'react';
import redFlower from '../images/redFlowers.jpg';

class pastSuccess extends React.Component {
	render() {
		return (
			<div>
					{/* <div className="ui divider"></div> */}
					<img className="ui fluid image" src={redFlower} />

			</div>
		)
	}
}

export default pastSuccess;



